import React from "react"
import styled from "styled-components"
import Resources from "../../resources/afisofom-resources.json"
import { graphql, StaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaMap,
  FaPhone,
  FaTwitter,
} from "react-icons/fa"
import { useSiteContent } from "../hooks/use-site-content"

const FooterWrapper = styled.div`
  width: 100vw;
  height: auto;
  font-family: var(--font-footer-normal);
  color: var(--white-color);
  background-color: var(${Resources.menu_mobile.footer.bg_color});
`

const FooterContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1em;
  padding: 30px 0;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: 1fr;
  }
`

const FooterRightContainer = styled.div`
  display: grid;
  justify-content: flex-end;
  align-items: center;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    justify-content: center;
    grid-row: 2;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    justify-content: center;
    grid-row: 2;
  }
`

const FooterCenterContainer = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-row: 3;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-row: 3;
  }
`

const FooterImageContainer = styled.div`
  max-width: 100%;
  max-height: auto;

  .img-wrapper {
    max-width: 40%;
    max-height: 200px;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    .img-wrapper {
      max-width: 100%;
      max-height: 150px;
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .img-wrapper {
      max-width: 100%;
    }
  }
`

const FooterRightsText = styled.label`
  font-family: var(${Resources.footer.font});
  font-size: ${Resources.footer.size};
  color: var(${Resources.footer.color});
  margin: 0;
  text-align: center;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    font-size: ${Resources.footer.size_med};
    margin-top: 15px;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.footer.size_med};
    margin-top: 30px;
  }
`

const GridCondusef = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-items: start;
  grid-gap: 2rem;
  .item-condusef {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .item-condusef-special {
    grid-column: 1 / 6;
  }

  .bold {
    font-style: italic;
  }

  @media only screen and (max-width: 991px) {
    grid-template-columns: 1fr;
    .item-condusef-special {
      grid-column: 1;
    }

    grid-gap: 1rem;
  }
`

const FooterIconContainer = styled.div``

const MenuMobileIconLegend = styled.div`
  display: grid;
  grid-template-columns: 2fr 8fr;
  justify-content: flex-start;
  align-items: center;
  padding-top: 15px;

  .footer-icon {
    font-size: ${Resources.menu_mobile.footer.icon_size};
    color: var(${Resources.menu_mobile.footer.color});
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-icon {
      font-size: ${Resources.menu_mobile.footer.icon_size_med};
      color: var(${Resources.menu_mobile.footer.color});
    }
  }
`

const MenuMobileFooterText = styled.p`
  font-family: var(${Resources.footer.font});
  font-size: ${Resources.menu_mobile.footer.size};
  color: var(${Resources.menu_mobile.footer.color});
  margin: 0;

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.menu_mobile.footer.size_med};
  }
`

const PrimaryNavbarIconsList = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;

  .icon-primary {
    font-size: ${Resources.footer.icon.size} !important;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    padding-top: 30px;
    padding-bottom: 30px;

    .icon-primary {
      font-size: ${Resources.menu_mobile.footer.icon_size_med} !important;
    }
  }
`

const PrimaryNavbarIcon = styled.a`
  color: var(${Resources.navbar.secondary.link.color});
  font-family: var(${Resources.navbar.primary.link.font});
  font-size: ${Resources.navbar.primary.link.size};
  text-decoration: none;
  margin-right: 22px;

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    margin-right: 44px;
  }
`

const FooterPrivacyPolicyText = styled(Link)`
  font-family: var(${Resources.menu_mobile.footer.font});
  font-size: ${Resources.menu_mobile.footer.size};
  color: var(${Resources.menu_mobile.footer.color});
  margin: 0;
  text-align: center;
  text-decoration: none;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    text-align: center;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.menu_mobile.footer.size_med};
    text-align: center;
  }
`

const Footer = () => {
  const siteContent = useSiteContent()
  return (
    <StaticQuery
      query={graphql`
        query {
          img: file(relativePath: { eq: "main-logo.png" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
          condusef: file(relativePath: { eq: "condusef.png" }) {
            childImageSharp {
              fixed(quality: 100, width: 150, height: 79) {
                ...GatsbyImageSharpFixed_tracedSVG
              }
            }
          }

          buro: file(relativePath: { eq: "buro.png" }) {
            childImageSharp {
              fixed(quality: 100, width: 80, height: 80) {
                ...GatsbyImageSharpFixed_tracedSVG
              }
            }
          }
          pdf: file(relativePath: { eq: "pdf.png" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 900) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      `}
      render={data => (
        <FooterWrapper>
          <div className="container" style={{ marginBottom: 0, marginTop: 0 }}>
            <FooterContentWrapper>
              <FooterImageContainer>
                <Img
                  fluid={data.img.childImageSharp.fluid}
                  className="img-wrapper"
                  imgStyle={{
                    objectFit: "contain",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </FooterImageContainer>
              <FooterCenterContainer>
                <PrimaryNavbarIconsList>
                  <PrimaryNavbarIcon
                    href={siteContent.navbar.social_links.fb}
                    target="_blank"
                  >
                    <FaFacebookF className="icon-primary" />
                  </PrimaryNavbarIcon>
                  <PrimaryNavbarIcon
                    href={siteContent.navbar.social_links.twitter}
                    target="_blank"
                  >
                    <FaTwitter className="icon-primary" />
                  </PrimaryNavbarIcon>
                  <PrimaryNavbarIcon
                    href={siteContent.navbar.social_links.linkedin}
                    target="_blank"
                  >
                    <FaLinkedinIn className="icon-primary" />
                  </PrimaryNavbarIcon>
                  <PrimaryNavbarIcon
                    href={siteContent.navbar.social_links.instagram}
                    style={{ marginRight: 0 }}
                    target="_blank"
                  >
                    <FaInstagram className="icon-primary" />
                  </PrimaryNavbarIcon>
                </PrimaryNavbarIconsList>
                <FooterPrivacyPolicyText to="/aviso-de-privacidad/">
                  {Resources.privacy_policy.title}
                </FooterPrivacyPolicyText>
                <FooterRightsText>
                  {Resources.footer.copyright_text_large}
                </FooterRightsText>
              </FooterCenterContainer>
              <FooterRightContainer>
                <FooterIconContainer>
                  <MenuMobileIconLegend>
                    <FaMap className="footer-icon" />
                    <MenuMobileFooterText
                      onClick={() => openMap(siteContent.footer.map_url)}
                      style={{ cursor: "pointer" }}
                    >
                      {siteContent.footer.address.map((line, key) => (
                        <React.Fragment key={key}>
                          {line} <br />
                        </React.Fragment>
                      ))}
                    </MenuMobileFooterText>
                  </MenuMobileIconLegend>
                </FooterIconContainer>
                <FooterIconContainer>
                  <MenuMobileIconLegend>
                    <FaPhone className="footer-icon" />
                    <MenuMobileFooterText>
                      {siteContent.footer.lista_telefonos.map((phone, key) => (
                        <React.Fragment key={key}>
                          {phone} <br />
                        </React.Fragment>
                      ))}
                    </MenuMobileFooterText>
                  </MenuMobileIconLegend>
                </FooterIconContainer>
              </FooterRightContainer>
            </FooterContentWrapper>
          </div>
          <div
            style={{ padding: "20px 0", background: "black" }}
            id="note-footer"
          >
            <div className="container">
              <p className="description small-font">
                * Costo anual total (CAT) promedio sin IVA es 18.9%, de
                financiamiento expresado en términos porcentuales anuales que,
                para fines informativos y de comparación, incorpora la totalidad
                de los costos y gastos inherentes a los créditos. Fecha de
                cálculo 11/10/2021, vigente hasta el 10/04/2022. Calculado con
                base en un crédito de $5,000,000.00 a un plazo de 6 meses con
                una tasa de interés de TIIE de 4.9825% + 150 puntos base + 3%
                (comisión por apertura).
              </p>

              <p className="description small-font">
                TIPO DE CRÉDITO | COMISIÓN
              </p>
              <p className="description small-font">
                Habilitación y/o Avío | Tasa de interés 19.5% anual; 3% comisión
                por apertura, y 2% comisión por supervisión y seguimiento.
              </p>
              <p className="description small-font">
                Refaccionario | Tasa de interés 19.5% anual; 3% comisión por
                apertura, y 2% comisión por supervisión y seguimiento.
              </p>
              <p className="description small-font">
                Cuenta Corriente | Tasa de interés 19% anual; 3% comisión por
                apertura.
              </p>
              <p className="description small-font">
                Simple | Tasa de interés 19% anual; 3% comisión por apertura.
              </p>
              <p className="description small-font">
                Requisitos de contratación:
              </p>
              <ul>
                <li className="description small-font">
                  Contar con un Buró de crédito sano
                </li>
                <li className="description small-font">
                  Proporcionar una garantía dos a uno del monto solicitado
                </li>
                <li className="description small-font">
                  Ingresos reflejados en los estados de cuenta que sustenten el
                  pago del crédito
                </li>
                <li className="description small-font">Contar con un aval</li>
              </ul>

              <p className="description small-font">
                AFISOFOM: Para su constitución y operación con carácter de
                sociedad financiera de objeto múltiple, entidad no regulada, no
                requiere autorización de la Secretaría de Hacienda y Crédito
                Público y únicamente está sujeta a la supervisión de la Comisión
                Nacional Bancaria y de Valores para efectos del artículo 56 de
                la Ley General de Organizaciones y Actividades Auxiliares del
                Crédito.
              </p>

              <Img
                fluid={data.pdf.childImageSharp.fluid}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
              <p className="description small-font">
                La información corresponde únicamente a la Entidad Financiera:
                Análisis, Filtro e Índices, S.A.P.I. de C.V., SOFOM E.N.R., para
                conocer la información de todo el sector se podrá acceder al
                sitio:
                <a
                  href="https://www.buro.gob.mx/general_gob.php?id_sector=69&id_periodo=31"
                  target="_blank"
                >
                  {" "}
                  https://www.buro.gob.mx/general_gob.php?id_sector=69&id_periodo=31
                </a>
              </p>
              <Img
                style={{
                  backgroundColor: "white",
                  padding: "2px 0",
                }}
                fixed={data.condusef.childImageSharp.fixed}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
              <p className="description small-font">
                55 5340 0999 / 800 999 80 80 / www.condusef.gob.mx
              </p>

              <Img
                fixed={data.buro.childImageSharp.fixed}
                imgStyle={{
                  objectFit: "contain",
                  width: "80px",
                  height: "80px",
                }}
              />
              <p className="description small-font">
                Es una herramienta de consulta y difusión con la que podrás
                conocer los productos que ofrecen las entidades financieras, sus
                comisiones y tasas, las reclamaciones de los usuarios, las
                prácticas no sanas en que incurren, las sanciones
                administrativas que les han impuesto, las cláusulas abusivas de
                sus contratos y otra información que resulte relevante para
                informarte sobre su desempeño.
              </p>
              <p className="description small-font">
                Con el Buró de Entidades Financieras, se logrará saber quién es
                quién en bancos, seguros, sociedades financieras de objeto
                múltiple, cajas de ahorro, afores, entre otras entidades.
              </p>
              <p className="description small-font">
                Con ello, podrás comparar y evaluar a las entidades financieras,
                sus productos y servicios y tendrás mayores elementos para
                elegir lo que más te convenga.
              </p>
              <p className="description small-font">
                Esta información te será útil para elegir un producto financiero
                y también para conocer y usar mejor los que ya tienes.
              </p>
              <p className="description small-font">
                Este Buró de Entidades Financieras, es una herramienta que puede
                contribuir al crecimiento económico del país, al promover la
                competencia entre las instituciones financieras; que impulsará
                la transparencia al revelar información a los usuarios sobre el
                desempeño de éstas y los productos que ofrecen y que va a
                facilitar un manejo responsable de los productos y servicios
                financieros al conocer a detalle sus características.
              </p>
              <p className="description small-font">
                Lo anterior, podrá derivar en un mayor bienestar social, porque
                al conjuntar en un solo espacio tan diversa información del
                sistema financiero, el usuario tendrá más elementos para
                optimizar su presupuesto, para mejorar sus finanzas personales,
                para utilizar correctamente los créditos que fortalecerán su
                economía y obtener los seguros que la protejan, entre otros
                aspectos.
              </p>
              <p className="description small-font">
                El Buró de Entidades Financieras contiene información de
                AFISOFOM sobre nuestro desempeño frente a los Usuarios, por la
                prestación de productos y servicios.
              </p>
              <p className="description small-font">
                Para mayor consulta:{" "}
                <a
                  href="https://webapps.condusef.gob.mx/SIPRES/jsp/home_publico.jsp?idins=1942"
                  target="_blank"
                  rel="noopener"
                >
                  https://webapps.condusef.gob.mx/SIPRES/jsp/home_publico.jsp?idins=1942
                </a>
              </p>
              <div className="description small-font">
                <p> Datos institucionales:</p>
                <GridCondusef>
                  <div className="item-condusef">
                    <span>Actualizados el:</span>
                    <span className="bold">05/09/2014</span>
                  </div>
                  <div className="item-condusef">
                    <span>Nombre corto:</span>
                    <span className="bold">AFI Sofom</span>
                  </div>
                  <div className="item-condusef">
                    <span>Estatus:</span>
                    <span className="bold">En operación</span>
                  </div>
                  <div className="item-condusef">
                    <span>RFC:</span>
                    <span className="bold">AFI070521RE8</span>
                  </div>
                  <div className="item-condusef">
                    <span>Clave de registro:</span>
                    <span className="bold">690257</span>
                  </div>
                  <div className="item-condusef">
                    <span>Sector:</span>
                    <span className="bold">
                      Sociedades Financieras de Objeto Múltiple E.N.R.
                    </span>
                  </div>
                  <div className="item-condusef">
                    <span>Supervisora:</span>
                    <span className="bold">CONDUSEF</span>
                  </div>
                  <div className="item-condusef">
                    <span>Entidad:</span>
                    <span className="bold">Nacional</span>
                  </div>
                  <div className="item-condusef">
                    <span>Inicio de operaciones:</span>
                    <span className="bold">21/05/2007</span>
                  </div>
                  <div className="item-condusef">
                    <span>Página web:</span>
                    <span className="bold">www.afisofom.com</span>
                  </div>
                  <div className="item-condusef">
                    <span>Correo electrónico:</span>
                    <span className="bold">asolorzano@afisofom.com</span>
                  </div>
                  <div className="item-condusef">
                    <span>Alta en SIPRES:</span>
                    <span className="bold">11/10/2007</span>
                  </div>
                  <div className="item-condusef item-condusef-special">
                    <span>Datos de contrato con SIC:</span>
                    <span className="bold">
                      Trans Unión de México, S.A., Sociedad de Información
                      Crediticia, Vigencia: 07/08/2012 - Indefinido - Último
                      cumplimiento: 07/10/2021
                    </span>
                    <span className="bold">
                      Dun & Bradstreet, S.A. Sociedad de Información Crediticia,
                      Vigencia: 07/08/2012 - Indefinido - Último cumplimiento:
                      07/10/2021
                    </span>
                  </div>
                </GridCondusef>
              </div>
            </div>
          </div>
        </FooterWrapper>
      )}
    />
  )
}

const openMap = url => {
  window.open(url, "_blank")
}

export default Footer
